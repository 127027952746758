import { Observable } from 'rxjs/Observable';
import { cancelRequest } from 'app/util/util';

export const RetrieveLocations = (query, places, repo, state) => {
  const criteria: LocationSearchCriteria = { searchText: query, locationTypeFilter: 1 };
  const sortCriterion: SortCriterion<number> = { field: 1, direction: 1 };
  const searchOptions: SearchOptions<any> = { pageSize: 10, sortCriterion };
  const geographySearchParameters: GeographySearchWrapper<LocationSearchCriteria, SearchOptions<any>> = { criteria, searchOptions } ;

  if (!repo || !query || query.length < 1) {
    return Observable.of([]);
  }

  cancelRequest(places);

  const request$ = repo.searchForLocation(geographySearchParameters)
    .catch(localError => {
      state?.setState({localError});
      return Observable.of();
    });
  request$.subscribe();

  return request$;
};
